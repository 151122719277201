<template>
  <el-row>
    <el-col :span="24">
      <ul>
        <li>
          <video controls src="http://www.chigeetech.com/images/videos/202203231719.mp4"></video>
          <p>微信导航互联</p>
        </li>
        <li>
          <video controls src="http://www.chigeetech.com/images/videos/Carplaylianjie.mp4"></video>
          <p>Carplay连接</p>
        </li>
        <li>
          <video controls src="http://www.chigeetech.com/images/videos/OTAshengji.mp4"></video>
          <p>OTA升级</p>
        </li>
        <li>
          <video controls src="http://www.chigeetech.com/images/videos/QQyinyueshiyongjiqiao.mp4"></video>
          <p>QQ音乐设置</p>
        </li>
        <li>
          <video controls src="http://www.chigeetech.com/images/videos/jingxiangtouping.mp4"></video>
          <p>安卓无线投屏</p>
        </li>
        <li>
          <video controls src="http://www.chigeetech.com/images/videos/lanyajishoujidelianjie.mp4"></video>
          <p>蓝牙设置与连接</p>
        </li>
        <li>
          <video controls src="http://www.chigeetech.com/images/videos/liuliangchongzhi.mp4"></video>
          <p>流量充值查询</p>
        </li>
        <li>
          <video controls src="http://www.chigeetech.com/images/videos/shebeibangding.mp4"></video>
          <p>APP绑定</p>
        </li>
        <li>
          <video controls src="http://www.chigeetech.com/images/videos/shipinxiazai.mp4"></video>
          <p>下载行车记录</p>
        </li>
      </ul>
      <div style="text-align: center; margin-top: 200px;">
        <p style="font-weight: 500; font-size: 35px; text-align: center;">联系我们</p>
        <img :src="img_erwei" alt="二维码" class="img_resize">
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data() {
    return {
      img_erwei: require('../../../../../public/images/support/erwei.png')
    }
  }
}
</script>
<style lang="less" scoped>
ul {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px 25px;
  li {
    width: 30%;
    height: 120px;
    // background-color: purple;
    margin-top: 20px;
    margin-bottom: 30px;
    video {
      width: 100%;
      height: 100%;
    }
    p {
      font-size: 12px;
      font-weight: 500;
    }
  }
}
.img_resize {
  width: auto;
  height: auto;
  max-width: 140px;
  max-height: 140px;
}
</style>